

/**
 * Nationaal Media Onderzoek (ComScore/ScorecardResearch)
 */
export default function useNMO(){

  const comScoreId: number = 16676137

  const cs_fpid = [+new Date(), Math.floor(Math.random() * 99999999 + 1000000)].join('_');
  const comScore: object[] = window._comscore || [];
  comScore.push({
    c2: comScoreId,
    nmo_01: null,
    nmo_02: '1',
    cs_fpid: cs_fpid,
    ns_site: 'total',
  });
    (function() {
      const s = document.createElement("script"),
        el = document.getElementsByTagName("script")[0];
      s.async = true;
    s.src = `https://sb.scorecardresearch.com/cs/${comScoreId}/beacon.js`;
    el.parentNode?.insertBefore(s, el);
  })();

}
