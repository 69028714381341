import React, {ReactNode} from 'react';
import {AppType} from "@/types/AppType";

// @ts-ignore
const AppContext = React.createContext<AppType>(undefined);

export type Props = {
  children: ReactNode;
  // app: AppType;
  legacyWidgetHostname: string;
};

const AppProvider = (props: Props) => {
  const { children } = props;

  // const [useLegacyWidget, setLegacyWidget] = React.useState<boolean>(false);


  return (
    <AppContext.Provider value={{
      useLegacyWidget: !!props.legacyWidgetHostname,
      legacyWidgetHostname: props.legacyWidgetHostname,
    }}>
      {children}
      </AppContext.Provider>
  );
}

const useApp = () => {

  return React.useContext(AppContext)

};

export { AppProvider, useApp };
